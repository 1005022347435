import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import { fetchUser as fetchUserApi, getUser, infoShape, injectInfos, Input, Loader, login as loginApi, Popup } from '@oceane/ui'
import { push } from 'connected-react-router'
import { getStyles } from 'isotope-client'
import { getUser as getUserAction, logUser as logUserAction } from 'isotope-client/modules/login/services/loginActions'
import { storeLocalUser as storeLocalUserApi } from 'isotope-client/modules/login/services/loginApi'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm, SubmissionError } from 'redux-form'

const validate = values => {
	const errors = {}
	if (!values.login) {
		errors.login = 'L\'identifiant est obligatoire'
	}
	if (!values.password) {
		errors.password = 'Le mot de passe est obligatoire'
	}
	return errors
}

const styles = theme => getStyles({
	buttonGrid: {
		textAlign: 'right'
	},
	loginButton: {
		width: 110
	},
	welcomeMessage: {
		marginTop: 20,
		marginBottom: 20
	},
	information: {
		backgroundColor: 'white',
		padding: '10px 10px',
		justifyContent: 'space-between',
		display: 'flex',
		flexDirection: 'column',
		border: '2px solid #6361a9',
		marginTop: 20
	},
	informationBloquant: {
		border: '2px solid red',
		marginBottom: 20
	}
})

const LoginForm = ({ handleSubmit, nextPathname, submitting, classes, infos: { version, informationsATO }, getInfos }) => {

	const [open, setOpen] = React.useState(false)
	const [actions, setActions] = React.useState(undefined)

	const loginSubmit = (data, dispatch) => {
		return new Promise((resolve, reject) => loginApi(data)
			.then((json) => {
				if (json) {
					dispatch(logUserAction(json.token))
					storeLocalUserApi(json)
					resolve(json.token)
				} else {
					reject({
						login: { id: 'global.erreurs.mauvaisIdentifiants' }
					})
				}
			})
			.catch(e => {
				if (e && e.response && (e.response.status === 400 || e.response.status === 401)) {
					if (e.login && e.login.id && e.login.id === 'portail.ferme') {
						getInfos()
					} else {
						reject(e)
					}
				}
				reject({
					login: { id: 'global.erreurs.connexionImpossible' }
				})

			})
		).then((token) => fetchUserApi(token)
		).then((user) => {
			dispatch(getUserAction(user))
		}).then(() => {
			if (nextPathname) {
				dispatch(push(nextPathname))
			} else {
				dispatch(push('/'))
			}
		}).catch(e => {
			throw new SubmissionError(e)
		})
	}

	React.useEffect(() => {
		if (informationsATO && informationsATO.titre) {
			setOpen(true)
			if (!informationsATO.bloquant) {
				setActions([
					<Button key="close" variant="contained" color="primary" onClick={() => setOpen(false)}>
						<FormattedMessage id="global.btn.fermer" />
					</Button>
				])
			} else {
				setActions(undefined)
			}
		}
	}, [informationsATO])

	if (!version) {
		return <Loader minHeight={false} />
	}

	return (
		<form onSubmit={handleSubmit(loginSubmit)} autoComplete="off">
			<Typography variant="h5" component="h1">
				Authentification
			</Typography>
			{version && <Typography variant="body1" className={classes.welcomeMessage}>
				Oceane V{version}
			</Typography>}
			<Field
				component={Input}
				label="Identifiant *"
				name="login"
				placeholder="Identifiant de connexion"
				fullWidth
			/>
			<Field
				component={Input}
				type="password"
				label="Mot de passe *"
				name="password"
				placeholder="Mot de passe"
				fullWidth
			/>
			<Grid container>
				<Grid item xs={12} className={classes.buttonGrid}>
					<Button
						type="submit"
						disabled={submitting}
						variant="contained"
						color="primary"
						className={classes.loginButton}
					>
						Valider
					</Button>
				</Grid>
			</Grid>
			{informationsATO && informationsATO.contenu && <Popup
				open={open}
				actions={actions}
				title={informationsATO.titre}
				content={<Paper elevation={2} className={`${classes.information} ${informationsATO.bloquant ? classes.informationBloquant : ''}`}>
					<Typography variant="body1">
						<span dangerouslySetInnerHTML={{ __html: informationsATO.contenu }} />
					</Typography>
				</Paper>}
			/>}
		</form>
	)
}

LoginForm.propTypes = {
	nextPathname: PropTypes.string,
	// redux-form
	submitting: PropTypes.bool,
	handleSubmit: PropTypes.func.isRequired,
	infos: infoShape
}

const mapStateToProps = (state) => ({
	user: getUser(state)
})

export default compose(
	injectInfos,
	connect(mapStateToProps),
	reduxForm({
		form: 'loginForm',
		validate
	}),
	withStyles(styles)
)(LoginForm)
